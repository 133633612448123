import * as React from "react"
import MainLayout from '../../layouts/main'
import {useContext, useEffect, useState} from "react";
import GlobalContext from "../../context/GlobalContext";
import {graphql, useStaticQuery} from "gatsby";
import Breadcrumps from "../../components/Breadcrumbs";
import Partners from "../home/partners";
import ContributiFiscali from "../home/contributi-fiscali";
import Help from "../home/help";
import GalleryButton from "../../components/GalleryButton";
import HeroPage from "../../components/HeroPage";
import SectionInstallazioni from "../../components/SectionInstallazioni";
import SeoComponent from "../../layouts/partials/SeoComponent";

const items = [
    { title: 'Prodotti', href: 'prodotti' },
    { title: 'Tende da sole' }
]
const installazioni = [
    {
        name: 'Le nostre installazioni',
        gallery: 'tenda-illuminazione-01.jpg',
        list: [
            {
                id: '1',
                src: '/prodotti/tendedasole/Installazione/tenda-illuminazione-01.jpg',
                thumb: '/thumb/tendedasole/Installazione/tenda-illuminazione-01.jpg',
            },
            {
                id: '2',
                src: '/prodotti/tendedasole/Installazione/tenda-illuminazione-02.jpg',
                thumb: '/thumb/tendedasole/Installazione/tenda-illuminazione-02.jpg',
            }
        ]
    },
]

const Tendedasole = ({pageContext}) => {
    const gContext = useContext(GlobalContext);
    const allImagesQuery = graphql`
            query {
              allFile(
                filter: {
                    relativeDirectory: { regex: "/(dettaglio)/" },
                    extension: {ne: "svg"}
                }
              ) {
                edges {
                  node {
                    base
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]      
                        placeholder: NONE         
                        quality: 100
                      )
                    }
                  }
                }
              }
            }
          `
    const {
        allFile: {edges: images},
    } = useStaticQuery(allImagesQuery)
    const [products, setProducts] = useState({});
    useEffect(() => {
        setProducts({
            prodotti: [
                {
                    name: 'Tende a bracci',
                    gallery: 'tende-a-bracci.jpg',
                    items: [
                        {
                            id: '1',
                            src: '/prodotti/tendedasole/TendeABracci/didue-01.jpg',
                            thumb: '/thumb/tendedasole/TendeABracci/didue-01.jpg',
                        },
                        {
                            id: '2',
                            src: '/prodotti/tendedasole/TendeABracci/didue-02.jpg',
                            thumb: '/thumb/tendedasole/TendeABracci/didue-02.jpg',
                        },
                        {
                            id: '3',
                            src: '/prodotti/tendedasole/TendeABracci/didue-03.jpg',
                            thumb: '/thumb/tendedasole/TendeABracci/didue-03.jpg',
                        },
                        {
                            id: '4',
                            src: '/prodotti/tendedasole/TendeABracci/dim-01_2.jpg',
                            thumb: '/thumb/tendedasole/TendeABracci/dim-01_2.jpg',
                        },
                        {
                            id: '5',
                            src: '/prodotti/tendedasole/TendeABracci/dim-02_2.jpg',
                            thumb: '/thumb/tendedasole/TendeABracci/dim-02_2.jpg',
                        },
                        {
                            id: '6',
                            src: '/prodotti/tendedasole/TendeABracci/dim-03_1.jpg',
                            thumb: '/thumb/tendedasole/TendeABracci/dim-03_1.jpg',
                        },
                        {
                            id: '7',
                            src: '/prodotti/tendedasole/TendeABracci/duck-01.jpg',
                            thumb: '/thumb/tendedasole/TendeABracci/duck-01.jpg',
                        },
                        {
                            id: '8',
                            src: '/prodotti/tendedasole/TendeABracci/duck-02.jpg',
                            thumb: '/thumb/tendedasole/TendeABracci/duck-02.jpg',
                        },
                        {
                            id: '9',
                            src: '/prodotti/tendedasole/TendeABracci/duck-03.jpg',
                            thumb: '/thumb/tendedasole/TendeABracci/duck-03.jpg',
                        },
                        {
                            id: '10',
                            src: '/prodotti/tendedasole/TendeABracci/scrigno-01.jpg',
                            thumb: '/thumb/tendedasole/TendeABracci/scrigno-01.jpg',
                        },
                        {
                            id: '11',
                            src: '/prodotti/tendedasole/TendeABracci/scrigno-02.jpg',
                            thumb: '/thumb/tendedasole/TendeABracci/scrigno-02.jpg',
                        },
                        {
                            id: '12',
                            src: '/prodotti/tendedasole/TendeABracci/scrigno-03.jpg',
                            thumb: '/thumb/tendedasole/TendeABracci/scrigno-03.jpg',
                        },
                        {
                            id: '13',
                            src: '/prodotti/tendedasole/TendeABracci/segno-01.jpg',
                            thumb: '/thumb/tendedasole/TendeABracci/segno-01.jpg',
                        },
                        {
                            id: '14',
                            src: '/prodotti/tendedasole/TendeABracci/segno-02.jpg',
                            thumb: '/thumb/tendedasole/TendeABracci/segno-02.jpg',
                        },
                    ]
                },
                {
                    name: 'Tende a caduta',
                    gallery: 'tende-a-caduta.jpg',
                    items: [
                        {
                            id: '15',
                            src: '/prodotti/tendedasole/TendeABracci/segno-03.jpg',
                            thumb: '/thumb/tendedasole/TendeABracci/segno-03.jpg',
                        },
                        {
                            id: '16',
                            src: '/prodotti/tendedasole/TendeACaduta/dim-01.jpg',
                            thumb: '/thumb/tendedasole/TendeACaduta/dim-01.jpg',
                        },
                        {
                            id: '17',
                            src: '/prodotti/tendedasole/TendeACaduta/dim-02.jpg',
                            thumb: '/thumb/tendedasole/TendeACaduta/dim-02.jpg',
                        },
                        {
                            id: '18',
                            src: '/prodotti/tendedasole/TendeACaduta/dim-03.jpg',
                            thumb: '/thumb/tendedasole/TendeACaduta/dim-03.jpg',
                        },
                        {
                            id: '19',
                            src: '/prodotti/tendedasole/TendeACaduta/inox-line-01_0.jpg',
                            thumb: '/thumb/tendedasole/TendeACaduta/inox-line-01_0.jpg',
                        },
                        {
                            id: '20',
                            src: '/prodotti/tendedasole/TendeACaduta/inox-line-02_0.jpg',
                            thumb: '/thumb/tendedasole/TendeACaduta/inox-line-02_0.jpg',
                        },
                        {
                            id: '21',
                            src: '/prodotti/tendedasole/TendeACaduta/opere-artigiane-01.jpg',
                            thumb: '/thumb/tendedasole/TendeACaduta/opere-artigiane-01.jpg',
                        },
                        {
                            id: '22',
                            src: '/prodotti/tendedasole/TendeACaduta/opere-artigiane-02.jpg',
                            thumb: '/thumb/tendedasole/TendeACaduta/opere-artigiane-02.jpg',
                        },
                        {
                            id: '23',
                            src: '/prodotti/tendedasole/TendeACaduta/teka-01.jpg',
                            thumb: '/thumb/tendedasole/TendeACaduta/teka-01.jpg',
                        },
                        {
                            id: '24',
                            src: '/prodotti/tendedasole/TendeACaduta/teka-04.jpg',
                            thumb: '/thumb/tendedasole/TendeACaduta/teka-04.jpg',
                        },
                        {
                            id: '25',
                            src: '/prodotti/tendedasole/TendeACaduta/teka-05.jpg',
                            thumb: '/thumb/tendedasole/TendeACaduta/teka-05.jpg',
                        },
                        {
                            id: '26',
                            src: '/prodotti/tendedasole/TendeInTrazione/zerogradi-01.jpg',
                            thumb: '/thumb/tendedasole/TendeInTrazione/zerogradi-01.jpg',
                        },
                        {
                            id: '27',
                            src: '/prodotti/tendedasole/TendeInTrazione/zerogradi-02.jpg',
                            thumb: '/thumb/tendedasole/TendeInTrazione/zerogradi-02.jpg',
                        },
                        {
                            id: '28',
                            src: '/prodotti/tendedasole/TendeInTrazione/zerogradi-03.jpg',
                            thumb: '/thumb/tendedasole/TendeInTrazione/zerogradi-03.jpg',
                        },
                    ]
                },
                {
                    name: 'Tende in trazione',
                    gallery: 'tende-in-trazione.jpg',
                    items: [
                        {
                            id: '29',
                            src: '/prodotti/tendedasole/TendeInTrazione/zerogradi-04.jpg',
                            thumb: '/thumb/tendedasole/TendeInTrazione/zerogradi-04.jpg',
                        },
                        {
                            id: '30',
                            src: '/prodotti/tendedasole/Cappottine/base-01.jpg',
                            thumb: '/thumb/tendedasole/Cappottine/base-01.jpg',
                        },
                        {
                            id: '31',
                            src: '/prodotti/tendedasole/Cappottine/base-02.jpg',
                            thumb: '/thumb/tendedasole/Cappottine/base-02.jpg',
                        },
                        {
                            id: '32',
                            src: '/prodotti/tendedasole/Cappottine/compasso-01.jpg',
                            thumb: '/thumb/tendedasole/Cappottine/compasso-01.jpg',
                        },
                        {
                            id: '33',
                            src: '/prodotti/tendedasole/Cappottine/semitondo-01.jpg',
                            thumb: '/thumb/tendedasole/Cappottine/semitondo-01.jpg',
                        },
                        {
                            id: '34',
                            src: '/prodotti/tendedasole/Cappottine/tondo-01.jpg',
                            thumb: '/thumb/tendedasole/Cappottine/tondo-01.jpg',
                        },
                        {
                            id: '35',
                            src: '/prodotti/tendedasole/SchermatureZip/11qu13qu-01.jpg',
                            thumb: '/thumb/tendedasole/SchermatureZip/11qu13qu-01.jpg',
                        },
                        {
                            id: '36',
                            src: '/prodotti/tendedasole/SchermatureZip/11qu13qu-02.jpg',
                            thumb: '/thumb/tendedasole/SchermatureZip/11qu13qu-02.jpg',
                        },
                        {
                            id: '37',
                            src: '/prodotti/tendedasole/SchermatureZip/click-zip-01_1.jpg',
                            thumb: '/thumb/tendedasole/SchermatureZip/click-zip-01_1.jpg',
                        },
                        {
                            id: '38',
                            src: '/prodotti/tendedasole/SchermatureZip/click-zip-02.jpg',
                            thumb: '/thumb/tendedasole/SchermatureZip/click-zip-02.jpg',
                        },
                        {
                            id: '39',
                            src: '/prodotti/tendedasole/SchermatureZip/click-zip-twin.jpg',
                            thumb: '/thumb/tendedasole/SchermatureZip/click-zip-twin.jpg',
                        },
                    ]
                },
            ],
        })
    }, [gContext])
    return (
        <MainLayout pageContext={pageContext}>
            <HeroPage
                image={gContext.getImageFromName(images, 'GIBUS_NODO_tenda_a_bracci.jpg')}
                imageAlt={'GIBUS_NODO_tenda_a_bracci.jpg'}
                title={'Tende da sole'}
            />
            <Breadcrumps  items={items}/>

            <div className="max-w-[1400px] mx-auto py-16 px-4 sm:px-6 lg:px-8 relative w-full">
                <div className="w-full text-justify" dangerouslySetInnerHTML={{ __html: gContext.t('tendedasole.intro') }}></div>

                <SectionInstallazioni items={installazioni} images={images} classes={'mb-16'}/>

                <h2 className="title-section-light text-center mb-16">{gContext.t('Tutte le tipologie disponibili')}</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-8 sm:gap-y-16 mb-8">
                    {(products?.prodotti || []).map((item) => (
                        <div key={item.name} className="min-h-[300px]">
                            <GalleryButton image={gContext.getImageFromName(images, item.gallery)} imageAlt={`image`} items={item.items} />
                        </div>
                    ))}
                </div>
            </div>

            <ContributiFiscali/>
            <Help/>
            <Partners/>
        </MainLayout>
    )
}

export default Tendedasole

export const Head = ({pageContext}) => (
    <SeoComponent pageContext={pageContext} />
)